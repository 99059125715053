import { Col, Divider, Row } from "antd";
import { Forma } from "./forma/Forma";
import { Listado } from "./listado/Listado";

export const Mensajes = () => {
  return (
    <div>
    
      <Row>
        <Col xs={24}>
          <Forma />
        </Col>
        <Col xs={24}>
          <Listado />
        </Col>
      </Row>
    </div>
  );
};
