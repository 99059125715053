import { Tag } from "antd";
import PropTypes from "prop-types";
import { ESTATUS } from "../../constants/const";


export const Estatus = ({ estatus }) => {
  if (!estatus) return <></>;

  if (estatus === ESTATUS.PENDIENTE) return <Tag color="gold">{estatus}</Tag>;
  if (estatus === ESTATUS.APROBADO) return <Tag color="green">{estatus}</Tag>;
  if (estatus === ESTATUS.RECHAZADO) return <Tag color="red">{estatus}</Tag>;

  return <div>Estatus</div>;
};

// props validatiopn
Estatus.propTypes = {
  estatus: PropTypes.string.isRequired,
};
