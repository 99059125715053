import { useState } from "react";
import { useDispatch } from "react-redux";
import { startPostMensaje } from "../../../../../context/actions/ptActions";
import { useParams } from "react-router";


export const useForma = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [mensaje, setMensaje] = useState();

  const submit = () => {
    const body = {
      mensaje,
      id,
    };
    console.log(body)
    dispatch(startPostMensaje(body));
    setMensaje();
  };

  return { mensaje, setMensaje, submit };
};
