export const logged = true;

export const opcionesMotivoIncidencia = [
  {label: "No se logra negociar costo", value: "No se logra negociar costo"},
  {label: "Arriba monto autorizado", value: "Arriba monto autorizado"},
  {
    label: "Pendiente de  autorización GNP",
    value: "Pendiente de  autorización GNP",
  },
  {label: "Rechazado por GNP", value: "Rechazado por GNP"},
  {
    label: "Pendiente documentación asegurado",
    value: "Pendiente documentación asegurado",
  },
  {
    label: "Pendiente de retiro del Concesionado",
    value: "Pendiente de retiro del Concesionado",
  },
  {
    label: "Documentación ilegible",
    value: "Documentación ilegible",
  },
  {
    label: "Requiere fotos para negociación",
    value: "Requiere fotos para negociación",
  },
  {
    label: "Proceso jurídico",
    value: "Proceso jurídico",
  },
  {
    label: "Sin fondo para pago",
    value: "Sin fondo para pago",
  },
];

export const opcionesResponsableIncidenciaPT = [
  {
    label: "Escotel",
    value: "Escotel",
  },
  {
    label: "Gnp",
    value: "Gnp",
  },
  {
    label: "Asegurado",
    value: "Asegurado",
  },
];
export const opcionesMotivoIncidenciaPT = [
  {
    label: "Documentos ilegibles o erróneos",
    value: "Documentos ilegibles o erróneos",
  },
  {
    label: "Documentado en barra",
    value: "Documentado en barra",
  },
  {
    label: "Documentado con financiera",
    value: "Documentado con financiera",
  },
  {
    label: "Pendiente modelo de refactura GNP",
    value: "Pendiente modelo de refactura GNP",
  },
  {
    label: "Asegurado no responde",
    value: "Asegurado no responde",
  },
  {
    label: "Layout incompleto o con datos incorrectos",
    value: "Layout incompleto o con datos incorrectos",
  },
];
export const opcionesCostos = [
  {
    label: "1er arrastre",
    value: "1er arrastre",
  },
  {
    label: "2do arrastre",
    value: "2do arrastre",
  },
  {
    label: "3er arrastre",
    value: "3er arrastre",
  },
  {
    label: "4to arrastre",
    value: "4to arrastre",
  },
  {
    label: "km recorrido",
    value: "km recorrido",
  },
  {
    label: "Banderazo",
    value: "Banderazo",
  },
  {
    label: "Maniobras fuera de camino",
    value: "Maniobras fuera de camino",
  },
  {
    label: "Maniobras dentro del camino",
    value: "Maniobras dentro del camino",
  },
  {
    label: "Abanderamiento con grúa",
    value: "Abanderamiento con grúa",
  },
  {
    label: "Abanderamiento manual",
    value: "Abanderamiento manual",
  },
  {
    label: "acondicionamiento",
    value: "acondicionamiento",
  },
  {
    label: "Custodia",
    value: "Custodia",
  },
  {
    label: "Pensión",
    value: "Pensión",
  },
  {
    label: "Concesionado no ubica unidad",
    value: "Concesionado no ubica unidad",
  },
];

export const estatusPt = [
  {label: "Todos", value: null},
  {label: "Inicio", value: 0},
  {label: "Contactando cliente", value: 1},
  {label: "Recolectando docs", value: 2},
  {label: "Validando docs", value: 3},
  {label: "Solicitando guia", value: 4},
  {label: "Confirmando guia", value: 5},
  {label: "Entregando docs", value: 6},
  {label: "Finalizado", value: 7},
];

export const refacturaPt = [
  {label: "Todos", value: 0},
  {label: "Falta modelo refactura", value: 1},
  {label: "Falta refactura", value: 2},
];


export const areaToShowCargarDatos = ['escotel']

export const ESTATUS = {
  PENDIENTE: "Pendiente",
  APROBADO: "Aprobado",
  RECHAZADO: "Rechazado",
};
