import { Alert, Button, Input, Modal, notification, Popconfirm, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startUpdateServicioPT } from "../../../../context/actions/ptActions";
import { useParams } from "react-router";
import { post } from '../../../../utils/fetch';
import { URL_BASE } from '../../../../constants/url';

export const AnuladoGnp = () => {
  const {id} = useParams();

  const options = [
    {value: 'Pagado, lo debe cancelar ESCOTEL', label: 'Pagado, lo debe cancelar ESCOTEL' },
    {value: 'En proceso de pago de daños, lo debe cancelar ESCOTEL', label: 'En proceso de pago de daños, lo debe cancelar ESCOTEL'},
    {value: 'Validación manual por parte de PTs GNP', label: 'Validación manual por parte de PTs GNP'},
    {value: 'Atendido por otra ventana, lo debe cancelar ESCOTEL', label:  'Atendido por otra ventana, lo debe cancelar ESCOTEL'},
    {value: 'Otros', label: 'Otros'},
  ]

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [textArea, setTextArea] = useState(false)
  const {TextArea} = Input;
  const [motivo, setMotivo] = useState();
  const {anuladoGNP, motivoAnulado} = useSelector(
    (state) => state.ptReducer.ptDetalle.servicioPT
  );

  const handleOk = () => {
    const body = {
      anuladoGNP: true,
      motivoAnulado: motivo,
      id,
    }
    if (motivo === 'Otros') {
      body.motivoAnulado = textArea
    }
    dispatch(
      startUpdateServicioPT(body)
    );
  }
  /////////
  if (anuladoGNP === true)
    return (
      <Alert
        style={{marginTop: 20}}
        message={`Anulado GNP: ${motivoAnulado}`}
        type="error"
      />
    );

  const generateAccess = async (id) => {
    const result = await post(`${URL_BASE}/api/aseguradosPTAuth/createAccess`, {id})
    if (result.code === 0) {
      notification.success({message: 'Acceso creado correctamente'})

    } else {
      notification.error({message: result.error})
    }
  }

  return (
    <div style={{marginTop: 20, display: 'flex', gap: '10px', alignItems: 'center'}}>
      <Button onClick={() => setShow(true)} size="small" danger>
        Anular GNP
      </Button>



      <Popconfirm
        title="Enviar acceso al asegurado"
        description="¿Está seguro de enviar el acceso al asegurado?"
        onConfirm={() => generateAccess(id)}
        onCancel={() => {
        }}
        okText="Si"
        cancelText="No"
      >


        <Button danger size="small" style={{marginLeft: '10px'}}>Crear y enviar
          acceso</Button>
      </Popconfirm>

      <Modal
        onOk={handleOk}
        onCancel={() => setShow(false)}
        open={show}
        title="Motivo de la Anulación"
      >
        <Select style={{width: '60%'}} options={options}
                onChange={(value) => setMotivo(value)}/>

        <div style={{marginTop: 20}}>

          {motivo === 'Otros' && <TextArea placeholder={'Escriba aquí el por que desea anular este servicio'}
                          onChange={(target) => setTextArea(target.target.value)}/>}
        </div>


      </Modal>

    </div>
  );
};
