import { useSelector } from "react-redux";

import { Col, Row } from "antd";
import { dateFromString } from "../../../../../utils/dateFromString";

export const Listado = () => {
  const { mensajesAsegurado } = useSelector((state) => state.ptReducer);

  return (
    <div style={{ marginTop: 20 }}>
      {mensajesAsegurado.map((m, i) => (
        <Row key={i} className="cajaMensaje" gutter={20}>
          <Col style={{color: 'teal', fontWeight: 600}}>
            {dateFromString(m.fecha).f1} - {m.usuarioNombre}
          </Col>
          <Col>{m.mensaje}</Col>
        </Row>
      ))}
    </div>
  );
};
