import { Button, Input } from "antd";
import { useForma } from "./useForma";

export const Forma = () => {
  const { mensaje, setMensaje, submit } = useForma();

  ///
  return (
    <div>
      <div style={{marginBottom: 10}}>
        <Input.TextArea
          value={mensaje}
          onChange={({ target }) => setMensaje(target.value)}
        />
      </div>
      <div>
        <Button
          disabled={!mensaje}
          onClick={submit}
          type="primary"
          style={{ width: "100%" }}
        >
          Enviar
        </Button>
      </div>
    </div>
  );
};
