import { Button, Col, Input, Modal, Popconfirm, Radio, Row, Space } from "antd";
import { USUARIOS_PUEDEN_ELIMINAR } from "../../constants/roles";
import { BotonEliminar } from "./BotonEliminar";
import { BotonDescargar } from "./BotonDescargar";
import { Estatus } from "./Estatus";
import { ESTATUS } from "../../constants/const";
import { TituloModal } from "../tituloModal/TituloModal";
import { useFormaEvaluar } from "./useFormaEvaluar";

export const ListFile = ({ doc, onClick }) => {
  const {
    submit,
    body,
    email,
    open,
    setOpen,
    openEvalFile,
    handleChange,
    disabled,
  } = useFormaEvaluar(doc);

  return (
    <>
      <div className="rowItemElemento">
        <Row gutter={[10, 10]} style={{ alignItems: "center" }}>
          <Col span={5}>
            <div
              onClick={openEvalFile}
              style={
                doc.estatusDocumento === ESTATUS.PENDIENTE
                  ? {
                      cursor: "pointer",
                    }
                  : {}
              }
            >
              <Estatus estatus={doc.estatusDocumento} />
            </div>
          </Col>
          <Col span={16}>{doc.nombreArchivo}</Col>

          <Col span={3}>
            <Space>
              <BotonDescargar onClick={onClick} doc={doc} />
              {USUARIOS_PUEDEN_ELIMINAR.includes(email) && (
                <BotonEliminar doc={doc} />
              )}
            </Space>
          </Col>
        </Row>
        {doc.motivoRechazo && (
          <Row
            style={{
              borderRadius: 3,
              marginTop: 2,
              padding: "2px 5px",
              color: "#fff",
              backgroundColor: "#db8181",
            }}
          >
            <Col span={24}>{doc.motivoRechazo}</Col>
          </Row>
        )}
        <Modal
          open={open}
          onCVancel={() => setOpen(false)}
          footer={[
            <Button onClick={() => setOpen(false)} key={1} size="small" danger>
              Cerrar
            </Button>,
          ]}
          title={
            <TituloModal titulo={"Evaluar Documento: " + doc.nombreArchivo} />
          }
        >
          <div style={{ paddingBottom: 20 }}>
            <Radio.Group
              name="estatusDocumento"
              onChange={handleChange}
              value={body.estatusDocumento}
            >
              <Radio value="Aprobado">Aprobado</Radio>
              <Radio value="Rechazado">Rechazado</Radio>
            </Radio.Group>
            {body.estatusDocumento === "Rechazado" && (
              <Input.TextArea
                placeholder="motivo de rechazo"
                name="motivoRechazo"
                onChange={handleChange}
                value={body.motivoRechazo}
              />
            )}
            <Popconfirm title="¿Esta seguro?" onConfirm={submit}>
              <Button
                disabled={disabled()}
                type="primary"
                style={{ width: "100%", marginTop: 5 }}
              >
                Enviar Evaluación
              </Button>
            </Popconfirm>
          </div>
        </Modal>
      </div>
    </>
  );
};
