import { types } from "../types/types";

const now = new Date();

now.setMonth(now.getMonth() - 3);

function formatDate(date) {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  return date.toISOString().split("T")[0];
}

const init = {
  ptServicios: [],
  ptDetalle: {servicioPT: {}, documentos: [], incidencias: []},

  filtro: {
    fechas: [formatDate(now), new Date().toISOString().split("T")[0]],
  },
  historial: [],
  filtroRefactura: "1",
  incidenciasAbiertas: [],
  listadoRefactura: [],
  listadoDocumentando: [],
  mensajesAsegurado: [],
};
export const ptReducer = (state = init, action) => {
  //
  switch (action.type) {

    case types.storeMensajesAsegurado:
      return {
        ...state,
        mensajesAsegurado: action.payload,
      };

    case types.storeListadoDocumentando:
      return {
        ...state,
        listadoDocumentando: action.payload,
      };

    case types.storeListadoRefactura:
      return {
        ...state,
        listadoRefactura: action.payload,
      };

    case types.storeIncidenciasAbiertas:
      return {
        ...state,
        incidenciasAbiertas: action.payload,
      };

    case types.setFiltroRefactura:
      return {
        ...state,
        filtroRefactura: action.payload,
      };

    case types.storePtDetalle:
      return {
        ...state,
        ptDetalle: action.payload,
      };

    case types.storePtServicios:
      return {
        ...state,
        ptServicios: action.payload,
      };

    case types.setFiltroPt:
      return {
        ...state,
        filtro: {...state.filtro, ...action.payload},
      };

    default:
      return state;
  }
};
