import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ESTATUS } from "../../constants/const";
import { startEvaluaDocumento } from "../../context/actions/ptDocumentosActions";
import { useParams } from "react-router";

export const useFormaEvaluar = (doc) => {
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.userReducer.user);
  const [open, setOpen] = useState(false);
  const [body, setBody] = useState({});
  const { id } = useParams();
  const openEvalFile = () => {
    if (doc.estatusDocumento !== ESTATUS.PENDIENTE) return;

    setOpen(true);
  };

  const handleChange = ({ target }) =>
    setBody({ ...body, [target.name]: target.value });

  const disabled = () =>
    body.estatusDocumento === "Rechazado" && !body.motivoRechazo;

  const submit = () => {
    dispatch(startEvaluaDocumento({ ...body, id: doc.id }, id));
    setOpen(false);
    setBody({});
  };

  return {
    submit,
    body,
    email,
    open,
    setOpen,
    openEvalFile,
    handleChange,
    disabled,
  };
};
