import { URL_PT_DOCUMENTOS } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post } from "../../utils/fetch";

import { notification } from "./notificationActions";
import { startGetPTServicio } from "./ptActions";

export const startEliminaDocumento = (id, PtId) => {
  return async (dispatch, getState) => {
    const url = `${URL_PT_DOCUMENTOS}/EliminaDocumento/${id}`;
    const result = await get(url);
    if (result.code === 0) {
      dispatch(startGetPTServicio(PtId));
      dispatch(notification(composeSuccessNotification("Documento eliminado")));
    } else {
      dispatch(
        notification(
          composeErrorNotification("DeleteDocumento", `${result.error}`)
        )
      );
    }
  };
};

///
export const startEvaluaDocumento = (body, id) => {
  return async (dispatch, getState) => {
    const url = `${URL_PT_DOCUMENTOS}/EvaluaDocumento`;
    const result = await post(url, body);

    console.log({ body });
    console.log({ res: result.data });
    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetPTServicio(id));
    } else {
      dispatch(
        notification(
          composeErrorNotification("EvaluarDocumento", `${result.error}`)
        )
      );
    }
  };
};
